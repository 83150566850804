import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { clamp, find, get, round } from 'lodash-es'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'

import Card from '@/Components/common/Card'
import HorizontalProgressBar from '@/Components/common/progressbars/HorizontalProgressBar'
import useTimer from '@/Utilities/useTimer'

export default function FertigationCard({
  pump,
  displayedProgram,
  programActiveStatus,
  programStatusColour,
}) {
  const [fertigationSecondsRemaining, setFertigationSecondsRemaining] = useState(0)
  const [timerOutOfSyncWarning, setTimerOutOfSyncWarning] = useState(false)

  const [
    fertigationFlowRate,
    fertigationFlowTotal,
    fertigationFlowRatePercentage,
  ] = useMemo(() => {
    const fertigationFlowStat = find(pump.stats, { key: 'flow' })
    const fertigationFlowTotal = find(pump.stats, { key: 'total' })?.value
    const fertigationRemainingTime = find(pump.stats, { key: 'remainingTime' })?.value

    let fertigationFlowRate = get(fertigationFlowStat, 'value', 0)
    let fertigationFlowRatePercentage = 0

    if (get(pump, 'flowMeter.type') === 'analogFlowMeter' && fertigationFlowRate) {
      fertigationFlowRatePercentage = clamp(fertigationFlowRate / get(pump, 'flowMeter.details.scaleMax', 0) * 100, 0, 100)
    }

    return [
      fertigationFlowRate,
      fertigationFlowTotal,
      fertigationFlowRatePercentage,
      fertigationRemainingTime,
    ]
  }, [displayedProgram, pump])

  const [fertigationRemaining, fertigationElapsedPercentage] = useMemo(() => {
    if (pump.fertigationBasis === 'time') {
      if (programActiveStatus !== 'running' && programActiveStatus !== 'paused') {
        return [get(pump, 'timing.expectedTotalTime', '00:00:00'), 0]
      }

      let remainingRuntime = '00:00:00'
      let remainingRuntimePercentage = 100

      const totalRuntimeSeconds = moment.duration(pump.fertigationDuration).asSeconds()

      if (fertigationSecondsRemaining > 0) {
        remainingRuntime = moment.utc(fertigationSecondsRemaining * 1000).format('HH:mm:ss')
        remainingRuntimePercentage = 100 - round(((fertigationSecondsRemaining / totalRuntimeSeconds) * 100), 4)
      }

      return [remainingRuntime, remainingRuntimePercentage]
    }

    if (pump.fertigationBasis === 'quantity') {
      if (programActiveStatus !== 'running' && programActiveStatus !== 'paused') {
        return [get(pump, 'fertigationQuantity'), 0]
      }

      let remainingQuantityPercentage = 100
      remainingQuantityPercentage = fertigationFlowTotal / pump.fertigationQuantity * 100

      return [get(pump, 'fertigationQuantity', 0), remainingQuantityPercentage]
    }
  }, [
    fertigationSecondsRemaining,
    pump,
    programActiveStatus,
  ])

  if (pump.fertigationBasis === 'time') {
    useTimer({
      identifyingKey: 'inputOutputId',
      identifyingId: pump.inputOutputId,
      displayed: pump,
      stats: pump.stats,
      setSecondsRemaining: setFertigationSecondsRemaining,
      status: programActiveStatus,
      setTimerOutOfSyncWarning: setTimerOutOfSyncWarning,
    })
  }

  return (
    <Card className="h-full">
      <Card.Header
        title="Fertigation"
        className="p-3.5"
      >
      </Card.Header>

      <Card.Body className="my-auto flex flex-col p-3.5">
        <div className="flex flex-col text-sm">
          <div className="flex justify-start">
            <div className="mr-1 text-gray-500">Booster:</div>{pump.inputOutput.name}
          </div>

          {pump.flowMeter && (
            <div className="flex flex-col justify-start">
              <div className="flex">
                <div className="mr-1 text-gray-500">Flow meter:</div>{pump.flowMeter.name}
              </div>

              <div className="flex">
                <div className="mr-1 text-gray-500">Total:</div>{fertigationFlowTotal || 'Unknown'}
              </div>
            </div>
          )}
        </div>

        <div className="flex grow flex-wrap items-center justify-evenly gap-4">
          {get(pump, 'flowMeter.type') === 'analogFlowMeter' && (
            <div className="mb-12 w-36 translate-y-10">
              <CircularProgressbarWithChildren
                value={fertigationFlowRatePercentage ? fertigationFlowRatePercentage : 0}
                strokeWidth="10"
                circleRatio="0.5"
                styles={buildStyles({
                  rotation: -0.25,
                  pathColor: programStatusColour.pathColor,
                  trailColor: programStatusColour.trailColor,
                })}
              >
                <div className="text-xs text-gray-500">Flow</div>
                <div className="text-xl font-medium">
                  {fertigationFlowRate ? round(fertigationFlowRate, 2) : '-'} gpm
                </div>
              </CircularProgressbarWithChildren>
            </div>
          )}

          {get(pump, 'flowMeter.type') !== 'analogFlowMeter' && (
            <div className="mb-12 w-36 translate-y-10 text-center">
              <div className="text-xs text-gray-500">Flow</div>
              <div className="text-xl font-medium">
                {fertigationFlowRate ? round(fertigationFlowRate, 2) : '-'} gpm
              </div>
            </div>
          )}

          <div className={classNames('text-center', {
            'w-40': !pump.flowMeter,
            'min-w-28 w-1/3': pump.flowMeter,
          })}>
            <HorizontalProgressBar
              className="h-3 min-w-28"
              colorName={programActiveStatus !== 'none' ? `status-${programActiveStatus}` : 'status-stopped'}
              percent={fertigationElapsedPercentage ? fertigationElapsedPercentage : 0}
            />

            {pump.fertigationBasis === 'time' && (
              <>
                <div className="mt-3 text-xs text-gray-500">
                  {timerOutOfSyncWarning && (
                    <Tippy
                      theme="light"
                      placement="auto"
                      trigger="click"
                      interactive={true}
                      content={
                        <>
                          Stop command not received.
                          Timers may be outdated.
                          Click <button onClick={() => {
                            window.location.reload()
                          }} className="text-status-queued underline">here</button> to refresh the page.
                          If the issue persists, contact support.
                        </>
                      }
                    >
                      <i className="fa fa-exclamation-triangle mr-1 text-red-500 hover:cursor-pointer"></i>
                    </Tippy>
                  )}

                  Remaining
                </div>
                <div className="text-xl font-medium">
                  {fertigationRemaining}
                </div>
              </>
            )}

            {pump.fertigationBasis === 'quantity' && (
              <>
                <div className="mt-3 text-xs text-gray-500">Remaining</div>
                <div className="text-xl font-medium">
                  {round(pump.fertigationQuantity - fertigationFlowTotal, 2) || '-'} {pump.fertigationQuantityUnitOfMeasurement}
                </div>
              </>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
