import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import { debounce, find, flatMap, forEach, head, isEmpty, map, reverse, sortBy, truncate } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'

import ActionIcon from '@/Components/ActionIcon'
import AlarmIcon from '@/Components/alerts/AlarmIcon'
import SearchInput from '@/Components/common/input/SearchInput'
import TableHeader from '@/Components/common/table/Header'
import DropdownList from '@/Components/DropdownList'
import { Anchor, RoundedIconButton } from '@/Components/form/Buttons'
import DayPicker from '@/Components/form/DayPicker'
import HUISelect from '@/Components/headless/form/Select'
import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'
import SkeletonTable from '@/Components/SkeletonTable'
import TableBasic from '@/Components/tables/base/Basic'
import ProgramsSection from '@/Components/tables/programs/ProgramsSection'
import Tabs from '@/Components/Tabs'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import { subscribe, unsubscribe } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { getPaginationMeta } from '@/Utilities/Pagination'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'

const Dot = styled.div`
  background-color: ${(props) => {
    return props.color
  }};
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
  height: 6px;
  width: 6px;
`

export default function ProgramSetsBySite(props) {
  const { site } = props

  // Utilities
  const auth = useAuth()
  const apiClient = useApiClient()

  // Internal component state
  const [lastQuery, setLastQuery] = useState(null)
  const [programSetStats, setProgramSetStats] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [tableDataLoading, setTableDataLoading] = useState(null)
  const [tableSearchTerm, setTableSearchTerm] = useState({
    type: null,
    value: null,
  })
  const [selectedTab, setSelectedTab] = useState(null)
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)

  const programSetStatus = useCallback((programSet) => {
    if (isEmpty(programSet.operationalStatuses)) {
      return '-'
    }

    return map(programSet.operationalStatuses, (status) => {
      return <Pill color={status.color} key={`${status.key}-${programSet.id}`}>
        <Dot color={status.color} /> {status.title}
      </Pill>
    })
  }, [tableData])

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      const currentQuery = {
        pageIndex,
        pageSize,
        filters,
      }

      setLastQuery(currentQuery)

      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['status', filters?.tab || ''],
        ['search', filters?.search?.value || ''],
        ['searchType', filters?.search?.type || 'programSet'],
        ['siteIds[]', props.siteId],
        ['with[]', 'programs.inputOutputs.mainLines'],
        ['with[]', 'programs.operationalStatuses'],
        ['with[]', 'programs.fertigationPumps'],
        ['with[]', 'programs.mainLine'],
        ['with[]', 'programs.activeAlarms'],
        ['with[]', 'site'],
        ['with[]', 'blocks'],
        ['with[]', 'activeAlarms'],
      ])

      try {
        const { data } = await apiClient.get(`/program-set/query?${query}`)

        let programSetData = map(formatKeys(data.programSets.data, 'camel'), (programSet) => {
          const programSetIsPaused = !isEmpty(find(programSet.operationalStatuses, ['key', 'paused']))
          const programSetIsRunning = !isEmpty(find(programSet.operationalStatuses, ['key', 'running']))

          const pauseOption = {
            label: (<span><i className="fa-sharp fa-solid fa-pause fa-lg mr-2 text-gray-500"></i> Pause program set</span>),
            topLine: true,
            disabled: !programSetIsRunning || !auth.can('pause-program'),
            onClick: () => {
              setModal({
                name: 'warning',
                data: {
                  endpoint: `/program-set/state/pause/${programSet.id}`,
                  title: 'Pause program set',
                  content: 'Pausing this program set will cause all related programs to pause as well. You have the ability to resume a program set but program stop times (if set) will be respected and proceed accordingly. The program set will automatically start again on the next scheduled day. Do you wish to proceed?',
                  successFlashMessage: `Successfully queued ${programSet.name} for pausing.`,
                  onComplete: () => {
                    setModal(null)
                  },
                  close: () => {
                    setModal(null)
                  },
                  onFailure: () => {
                    setModal(null)
                    setAlert({
                      type: 'error',
                      content: 'We were unable to process your request. Please try again.',
                    })
                  },
                },
              })
            },
          }

          const stopOption = {
            label: (<span><i className="fa-sharp fa-solid fa-stop fa-lg mr-2 text-red-500"></i> Stop program set</span>),
            topLine: true,
            disabled: !auth.can('stop-program'),
            onClick: () => {
              setModal({
                name: 'warning',
                data: {
                  endpoint: `/program-set/state/stop/${programSet?.id}`,
                  title: 'Stop program set',
                  content: 'Stopping this program set will cause all related programs to stop as well. You will not be able to resume the program set, program stop times will be respected and proceed accordingly. The program set will automatically start again on the next scheduled day. Do you wish to proceed?',
                  successFlashMessage: `Successfully queued ${programSet?.name} to stop.`,
                  onComplete: () => {
                    setModal(null)
                  },
                  close: () => {
                    setModal(null)
                  },
                  onFailure: () => {
                    setModal(null)
                    setAlert({
                      type: 'error',
                      content: 'We were unable to process your request. Please try again.',
                    })
                  },
                },
              })
            },
          }

          const resumeOption = {
            label: (<span><i className="fa-sharp fa-solid fa-play fa-lg mr-2 text-green-600"></i> Resume program set</span>),
            topLine: true,
            disabled: !programSetIsPaused || !auth.can('start-program'),
            onClick: () => {
              setModal({
                name: 'warning',
                data: {
                  endpoint: `/program-set/state/resume/${programSet.id}`,
                  title: 'Resume program set',
                  content: 'Are you sure you want to resume this set? Program stop times (if set) will still be respected and proceed accordingly. The program set will automatically start again on the next scheduled day.',
                  successFlashMessage: `Successfully queued ${programSet.name} to resume.`,
                  onComplete: () => {
                    setModal(null)
                  },
                  close: () => {
                    setModal(null)
                  },
                  onFailure: () => {
                    setModal(null)
                    setAlert({
                      type: 'error',
                      content: 'We were unable to process your request. Please try again.',
                    })
                  },
                },
              })
            },
          }

          let optionsToShow = []
          if (programSetIsRunning || !programSetIsPaused) {
            optionsToShow.push(pauseOption)
            optionsToShow.push(stopOption)
          } else {
            optionsToShow.push(resumeOption)
            optionsToShow.push(stopOption)
          }

          return {
            name: (
              <>
                <div>
                  {programSet.name}

                  {!isEmpty(programSet.activeAlarms) && (
                    <AlarmIcon type="programSet" uuid={programSet.uuid} />
                  )}
                </div>

                {programSet.description ? (
                  <Tippy content={programSet.description} delay={200} theme="light" disabled={programSet.description.length < 50} placement="right">
                    <div className="inline-block text-xs text-slate-400">
                      {truncate(programSet.description, { length: 50 })}
                    </div>
                  </Tippy>
                ) : null}
              </>
            ),
            status: programSetStatus(programSet),
            schedule: (
              <div className="text-primary hover:cursor-pointer">
                <Tippy
                  content={
                    <div className="flex">
                      <DayPicker initialSchedule={programSet.schedule || null} noBorder={true} />
                    </div>
                  }
                  delay={200}
                  theme="light"
                  placement="right"
                >
                  <div className="mr-2 inline-block">
                  View schedule
                  </div>
                </Tippy>
              </div>
            ),
            startTime: programSet.startTime,
            stopTime: programSet.stopTimeDisabled ? 'Disabled' : programSet.stopTime,
            stabilizationTime: `${programSet.stabilizationTime} ${programSet.stabilizationTimeUnitOfMeasurement}`,
            action: (
              <DropdownList
                icon={<ActionIcon />}
                options={[
                  {
                    label: 'Edit program set',
                    disabled: !auth.can('update-program'),
                    onClick: () => {
                      setModal({
                        name: 'set',
                        data: {
                          programSet: formatKeys(programSet, 'camel'),
                          isEditing: true,
                          onSave: () => {
                            getTableDataStart(currentQuery)
                          },
                        },
                      })
                    },
                  },
                  {
                    label: 'Delete program set',
                    disabled: !auth.can('delete-program'),
                    disabledWithReason: !isEmpty(programSet.programs) ? flatMap(programSet.programs, 'name') : null,
                    disabledWithReasonMessage: !isEmpty(programSet.programs) ? 'This program set cannot be deleted until all associated programs are removed or reassigned:' : null,
                    onClick: () => {
                      setModal({
                        name: 'warning',
                        data: {
                          title: 'Delete program set',
                          content: 'Please note that all related programs and its associated data will be permanently deleted. This action cannot be reversed.',
                          endpoint: `/program-set/delete/${programSet.id}`,
                          successFlashMessage: `${programSet.name} deleted successfully.`,
                          errorFlashMessage: `An error occurred while attempting to remove ${programSet.name}. Please try again`,
                          onComplete: async () => {
                            getTableDataStart(currentQuery)
                          },
                          savePreventionState: {
                            model: 'programSet',
                            id: programSet.id,
                            pendingChanges: programSet.pendingChanges,
                          },
                        },
                      })
                    },
                  },
                  ...optionsToShow,
                  {
                    label: 'Add program',
                    topLine: true,
                    onClick: () => {
                      setModal({
                        name: 'program',
                        data: {
                          program: { programSetId: programSet.id },
                          limitProgramSetsBySite: true,
                        },
                      })
                    },
                  },
                ]}
              />
            ),
            renderRowSubComponent: (
              <ProgramsSection
                getTableData={() => {
                  getTableDataStart(currentQuery)
                }}
                programs={programSet.programs || null}
              />
            ),
          }
        })

        let tableData = {
          ...data.programSets,
          data: programSetData,
        }

        setTableData(tableData)
        setTableDataLoading(false)
      } catch (error) {
        Sentry.captureException(error)
      }

    }, 250)
  }, [
    setTableData,
    setTableDataLoading,
    navigate,
    setModal,
    setAlert,
  ])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [getTableData])

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: () => {
            return null
          },
          id: 'expander',
          Cell: ({ row }) => {
            return (
              <span {...row.getToggleRowExpandedProps()}>
                <i className={row.isExpanded ? 'fa-regular fa-chevron-down icon' : 'fa-regular fa-chevron-right icon'}></i>
              </span>
            )
          },
          width: '5%',
        },
        {
          Header: 'Name',
          accessor: 'name',
          width: '15%',
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: '10%',
        },
        {
          Header: 'Schedule',
          accessor: 'schedule',
          width: '10%',
        },
        {
          Header: 'Start time',
          accessor: 'startTime',
          width: '10%',
        },
        {
          Header: 'Stop time',
          accessor: 'stopTime',
          width: '10%',
        },
        {
          Header: 'Stabilization time',
          accessor: 'stabilizationTime',
          width: '10%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
          style: { textAlign: 'right' },
        },
      ]
    }, [],
  )

  const filterTabs = useMemo(() => {
    return [
      {
        title: 'View all',
        key: null,
      },
      {
        title: 'Alarms',
        key: 'alarms',
      },
      {
        title: 'Stopped',
        key: 'stopped',
      },
      {
        title: 'Running',
        key: 'running',
      },
    ]
  }, [])

  const getHeaderPills = useMemo(() => {
    let pills = []

    if (tableData && programSetStats) {
      pills.push({
        title: `${tableData.total} Total`,
        color: '#175cd3',
        tabKey: null,
      })

      forEach(programSetStats, (programStat) => {
        pills.push({
          title: `${programStat.count} ${programStat.title}`,
          color: programStat.color,
        })
      })
    }

    return pills
  }, [tableData, programSetStats])

  // Map tabs and pill states together. We use this in the mobile view when we display a select instead of tabs,
  // with each option containing a pill to represent the count/state
  const filterTabsMappedWithPills = useMemo(() => {
    return map(filterTabs, (tab) => {
      const tabPill = getHeaderPills.find((pill) => {
        return pill.tabKey === tab.key
      })

      return {
        label: tab.title,
        value: tab.key,
        pill: {
          visible: tabPill !== undefined,
          title: tabPill?.title,
          color: tabPill?.color,
        },
      }
    })
  }, [getHeaderPills])

  const getProgramSetStats = useCallback(async () => {
    try {
      let { data: programSetStats } = await apiClient.get('/program/stats')

      return reverse(sortBy(programSetStats, 'count'))
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [])

  useEffect(() => {
    (async () => {
      const programSetStats = await getProgramSetStats()

      setProgramSetStats(programSetStats)
    })()
  }, [setProgramSetStats, getProgramSetStats])

  useEffect(() => {
    const updateProgramList = () => {
      getTableDataStart(getPaginationMeta(lastQuery))
    }

    const updateProgramListQuietly = () => {
      getTableData(getPaginationMeta(lastQuery))
    }

    if (lastQuery) {
      subscribe('programCreated', updateProgramList)
      subscribe('program.updated', updateProgramListQuietly)
    }

    return () => {
      unsubscribe('programCreated', updateProgramList)
      unsubscribe('program.updated', updateProgramListQuietly)
    }
  }, [lastQuery])

  const showProgramSetModal = useCallback(() => {
    setModal({
      name: 'set',
      data: {
        site: {
          name: site.name,
          id: site.id,
        },
      },
    })
  }, [site])

  return (
    tableData ?
      <TableBasic
        testId="tableProgramSetsBySite"
        mainTable={true}
        columns={tableColumns}
        data={tableData}
        loading={tableDataLoading}
        getTableData={getTableDataStart}
        filterTabs={filterTabs}
        searchTerm={tableSearchTerm}
        selectedTab={selectedTab}
        headerPills={getHeaderPills}
        header={
          <TableHeader
            testId="tableProgramSetsBySiteTableHeader"
            className="grid-flow-col"
          >
            <TableHeader.Title>Program Sets</TableHeader.Title>

            {getHeaderPills?.length && (
              <TableHeader.Pills className="col-span-2 row-start-2 mt-3 justify-self-start md:row-start-1 md:mt-0 md:ml-3 md:inline-block md:justify-self-start">
                <PillWrapper>
                  {map(getHeaderPills, (pill, index) => {
                    return (
                      <Pill color={pill.color} key={index}>{pill.title}</Pill>
                    )
                  })}
                </PillWrapper>
              </TableHeader.Pills>
            )}

            <TableHeader.Toolbar
              className={
                twMerge(
                  'grid justify-items-end gap-2.5 text-right',
                  { hidden: !auth.can('create-program') },
                )
              }
            >
              <div className="hidden md:flex">
                <Anchor
                  style={{ minWidth: '200px' }}
                  onClick={showProgramSetModal}
                >
                  Add program set
                </Anchor>
              </div>
              <div className="md:hidden">
                <RoundedIconButton
                  icon="plus"
                  className="bg-dark"
                  onClick={showProgramSetModal}
                />
              </div>
            </TableHeader.Toolbar>

            <TableHeader.SubHeader className="flex flex-col justify-between gap-3 @lg:flex-row @lg:items-center">
              <div className="hidden md:inline-block">
                <Tabs
                  tabs={filterTabs}
                  onChange={(selected) => {
                    if (selected !== selectedTab) {
                      setSelectedTab(selected)
                    }
                  }}
                  className="hidden md:inline-flex"
                />
              </div>

              <div className="block md:hidden">
                <HUISelect
                  className="mt-3 w-full rounded-md"
                  defaultValue={head(filterTabsMappedWithPills)}
                  options={filterTabsMappedWithPills}
                  onChange={(selected) => {
                    if (selected.value !== selectedTab) {
                      setSelectedTab(selected.value)
                    }
                  }}
                />
              </div>

              <TableHeader.Toolbar className="grid grid-flow-row gap-0 md:grid-flow-col">
                <SearchInput
                  className="block w-full flex-wrap"
                  term={tableSearchTerm}
                  onTermUpdate={(value) => {
                    return setTableSearchTerm({
                      ...tableSearchTerm,
                      value,
                    })
                  }
                  }
                />
              </TableHeader.Toolbar>
            </TableHeader.SubHeader>
          </TableHeader>
        }
      /> : <SkeletonTable />
  )
}
