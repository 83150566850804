import 'react-circular-progressbar/dist/styles.css'

import { Disclosure } from '@headlessui/react'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { featureGroup, latLngBounds, marker } from 'leaflet'
import { clamp,
  concat,
  each,
  filter,
  find,
  findIndex,
  first,
  get,
  groupBy,
  has,
  head,
  isEmpty,
  isFunction,
  isUndefined,
  map,
  maxBy,
  reduce,
  replace,
  round, set,
  some,
  startCase,
  uniqBy } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { useSetRecoilState } from 'recoil'

import ActionIcon from '@/Components/ActionIcon'
import Card from '@/Components/common/Card'
import DropdownList from '@/Components/DropdownList'
import DayPicker from '@/Components/form/DayPicker'
import Map from '@/Components/Map'
import Pill from '@/Components/pill/Pill'
import Tabs from '@/Components/Tabs'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import { types as ioTypes } from '@/Config/InputOutputs'
import FertigationCard from '@/Pages/site/overview/FertigationCard'
import InputOutputCards from '@/Pages/site/overview/InputOutputCards'
import MainLineCard from '@/Pages/site/overview/MainLineCard'
import { getByPath } from '@/Utilities/Accessors/Helpers'
import { mapIcons } from '@/Utilities/Icons/InputOutputIcons'
import { ioMapStatus } from '@/Utilities/inputOutput'
import labelIcon from '@/Utilities/Map/Icons/LabelIcon'
import { addBlocks } from '@/Utilities/Map/Map'
import { statusColor } from '@/Utilities/StatusColor'
import useAuth from '@/Utilities/useAuth'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useTimer from '@/Utilities/useTimer'
import useUserIdle from '@/Utilities/useUserIdle'

function DashboardOverview(props) {
  const [availableIoTypes, setAvailableIoTypes] = useState([])
  const [availableIo, setAvailableIo] = useState({})
  const [programSecondsRemaining, setProgramSecondsRemaining] = useState(0)
  const [programSetSecondsRemaining, setProgramSetSecondsRemaining] = useState(0)
  const [programSetSliderLoaded, setProgramSetSliderLoaded] = useState(false)
  const [programSliderLoaded, setProgramSliderLoaded] = useState(false)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [selectedMapTab, setSelectedMapTab] = useState('site')
  const [timerOutOfSyncWarning, setTimerOutOfSyncWarning] = useState(false)
  const setModal = useSetRecoilState(modalState)
  const setAlert = useSetRecoilState(pageAlertState)
  const programSetSlider = useRef()
  const programSlider = useRef()
  const didManuallyNavigateSlider = useRef(false)
  const mapLayerGroups = useRef({})
  const mapInstance = useRef(null)
  const mapMarkerIOs = useRef({})
  const refreshInterval = useRef(null)
  const navigate = useNavigate()
  const auth = useAuth()
  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const {
    programSetSliderIndex,
    programSetStats,
    programSliderIndex,
    programStats,
    setDashboardSelectedTab,
    setProgramSetSliderIndex,
    setProgramSliderIndex,
    site,
    sites,
    isLoading,
    updateDashboard,
    activeInputOutputAlarms,
  } = props

  useUserIdle(() => {
    updateDashboard()
  })

  const mapInit = useCallback((map) => {
    mapInstance.current = map
    setMapLoaded(true)
    addSiteMarker(false)
  }, [])

  useEffect(() => {
    if (!isEmpty(site) && mapLoaded) {
      addSiteMarker()
      addSiteBlocks(true)
      updateAvailableIoAndTypes()
      addIoTypeLayerGroups()
    }
  }, [
    site,
    programSetSliderIndex,
    mapLoaded,
  ])

  const siteHasAlarms = useMemo(() => {
    return !isEmpty(site.activeAlarms) || !isEmpty(site.area.activeAlarms)
  }, [site])

  const allProgramSets = useMemo(() => {
    return get(site, 'programSets', [])
  }, [site])

  const displayedProgramSet = useMemo(() => {
    return allProgramSets[programSetSliderIndex] || {}
  }, [programSetSliderIndex, allProgramSets])

  const displayedProgram = useMemo(() => {
    return get(allProgramSets, `${programSetSliderIndex}.programs.${programSliderIndex}`, {})
  }, [
    allProgramSets,
    programSetSliderIndex,
    programSliderIndex,
  ])

  const programSetStatusColour = useMemo(() => {
    if (get(site, 'programSets', false)) {
      return statusColor(get(site, `programSets.${programSetSliderIndex}.operationalStatuses`, null))
    }

    return {}
  }, [
    site,
    programSetSliderIndex,
    programSliderIndex,
  ])

  const programSetSiteAlarmStatusColour = useMemo(() => {
    if (siteHasAlarms) {
      return statusColor([{
        key: 'alarms',
        color: '#F04438',
      }])
    }

    return programSetStatusColour
  }, [siteHasAlarms, programSetStatusColour])

  const programStatusColour = useMemo(() => {
    if (get(site, 'programSets', false)) {
      return statusColor(get(displayedProgram, 'operationalStatuses', null))
    }

    return {}
  }, [displayedProgram, site])

  const programSiteAlarmStatusColour = useMemo(() => {
    if (siteHasAlarms) {
      return statusColor([{
        key: 'alarms',
        color: '#F04438',
      }])
    }

    return programStatusColour
  }, [siteHasAlarms, programStatusColour])

  const addSiteMarker = useCallback((siteData) => {
    const currentSite = siteData ? siteData : site

    if (!isEmpty(currentSite) && currentSite.lat && currentSite.lng && mapLoaded) {
      const operationalStatuses = get(currentSite, `programSets.${programSetSliderIndex}.operationalStatuses`, [])
      const firstOperationalStatusKey = siteHasAlarms ? 'failure' : get(head(operationalStatuses), 'key')

      let mapMarker = marker([currentSite.lat, currentSite.lng], {
        icon: labelIcon(currentSite.name, { overrideClass: firstOperationalStatusKey }),
        draggable: false,
      })

      mapMarker.addTo(mapInstance.current.groups.marker)

      if (!currentSite.blocks.length) {
        const bounds = latLngBounds()

        if (mapInstance.current.groups.marker) {
          bounds.extend(mapInstance.current.groups.marker.getBounds())
        }

        if (bounds.isValid()) {
          mapInstance.current.map.fitBounds(bounds, { maxZoom: 18 })
        }
      }
    }
  }, [
    mapLoaded,
    site,
    programSetSliderIndex,
    siteHasAlarms,
  ])

  /**
   * Applies IO type layer groups to the map.
   *
   * @returns {void}
   *
   * @example
   * addIoTypeLayerGroups();
   */
  const addIoTypeLayerGroups = useCallback(() => {
    map(ioTypes, (type) => {
      mapLayerGroups.current[type] = featureGroup()
    })
  }, [ioTypes, mapLayerGroups])

  /**
   * Refreshes the map layer groups based on the selected map tab.
   * If the selected map tab is not 'inputOutputs', the function does nothing.
   *
   * The function iterates through the `ioTypes` array and extends the `bounds` variable
   * with the bounds of each layer group found in `mapLayerGroups`. It also adds each
   * layer group to the map.
   *
   * Finally, it removes the `mapInstance.groups.marker` layer from the map.
   *
   * @returns {void}
   *
   * @param {Object} mapInstance - The current map instance.
   * @param {Object} mapLayerGroups - The map layer groups object.
   * @param {string} selectedMapTab - The selected map tab.
   */
  const refreshMapLayerGroups = useCallback(() => {
    if (selectedMapTab !== 'inputOutputs') {
      return
    }

    let bounds

    each(ioTypes, (ioType) => {
      if (!has(mapLayerGroups.current, ioType)) {
        return
      }

      if (!bounds) {
        bounds = mapLayerGroups.current[ioType].getBounds()
      } else {
        bounds.extend(mapLayerGroups.current[ioType].getBounds())
      }

      mapInstance.current.map.addLayer(mapLayerGroups.current[ioType])
    })

    mapInstance.current.map.removeLayer(mapInstance.current.groups.marker)
  }, [
    mapInstance,
    mapLayerGroups,
    selectedMapTab,
  ])

  useEffect(() => {
    // Clear all current and available layer groups for each IO type which is present
    each(mapLayerGroups.current, (layerGroup) => {
      layerGroup.clearLayers()
    })

    // Acquire the active running program for determining the associated IOs
    const runningProgram = find(
      getByPath(allProgramSets, '*.programs.*'),
      { operationalStatuses: [{ key: 'running' }] },
    )

    // Retrieve all site inputOutputs and append the fertigationPumps and main line's
    // flow meter
    const runningProgramInputOutputs = concat(
      get(runningProgram, 'inputOutputs', []),
      get(runningProgram, 'fertigationPumps', []),
      get(runningProgram, 'mainLine.flowMeter', []),
    )

    // Append a record for the main line valve if available
    const mainLineValveId = get(runningProgram, 'mainLine.mainValveId')

    if (mainLineValveId) {
      runningProgramInputOutputs.push({ id: mainLineValveId })
    }

    // Loop through available IOs and append update the markers on the map with relevant
    // states for each marker
    each(availableIo, (io) => {
      if (!io.lat || !io.lng) {
        return
      }

      const programStatusKey = head(runningProgram?.operationalStatuses)?.key
      const activeAlarm = find(activeInputOutputAlarms, {
        alarmableType: 'inputOutput',
        alarmableId: io.id,
      })

      const associatedToProgram = find(runningProgramInputOutputs, { id: io.id })
      const isInRunningProgram = programStatusKey === 'running' && associatedToProgram
      const iconStatus = ioMapStatus(io, activeAlarm, isInRunningProgram)
      const overrideClass = get(iconStatus, 'statusClass', isInRunningProgram ? 'bg-status-queued' : 'bg-status-stopped')
      const createIcon = mapIcons[replace(io.detailsType, 'inputOutput.', '')]
      const hasExistingMarker = has(mapMarkerIOs.current, io.id)
      const title = `${io.name} (${startCase(replace(io.detailsType, 'inputOutput.', ''))})`

      // Remove the existing marker if one present on the map
      if (hasExistingMarker) {
        const existingMarker = get(mapMarkerIOs.current, io.id)
        existingMarker?.remove()
      }

      // Create the marker
      let mapMarker = marker([io.lat, io.lng], {
        icon: createIcon({
          title: title,
          overrideClass: overrideClass,
        }),
        draggable: false,
      })

      set(mapMarkerIOs.current, io.id, mapMarker)
      mapMarker.addTo(mapLayerGroups.current[io.detailsType])

      if (activeAlarm) {
        mapMarker.bindPopup(`Alarm: ${get(activeAlarm, 'description')}`)
      }
    })

    refreshMapLayerGroups()
  }, [
    allProgramSets,
    availableIo,
    activeInputOutputAlarms,
    mapLayerGroups,
  ])

  /**
   * Updates the available IO (Input/Output) types and IO list.
   *
   * This function retrieves the input/output data from the program sets
   * and programs in the site. It combines the input/output data from
   * the programs' inputOutputs and mainLine.flowMeter properties.
   * The combined data is then processed to remove duplicates,
   * group them by type, and return the unique types with their counts.
   * The resulting unique types and the combined data are then
   * set in the state variables 'availableIoTypes' and 'availableIo'
   * respectively.
   *
   * @function updateAvailableIoAndTypes
   */
  const updateAvailableIoAndTypes = useCallback(() => {
    // Retrieve all site inputOutputs
    let inputOutputs = uniqBy(
      get(site, 'inputOutputs', []),
      'id',
    )

    const groupedInputOutputTypes = groupBy(inputOutputs, 'detailsType')
    const uniqueInputOutputTypes = map(groupedInputOutputTypes, (inputOutputTypes, inputOutputType) => {
      return {
        name: inputOutputType,
        count: inputOutputTypes.length,
      }
    })

    setAvailableIoTypes(uniqueInputOutputTypes)
    setAvailableIo(inputOutputs)
  }, [site])

  const addSiteBlocks = useCallback((enabled = true) => {
    if (!isEmpty(site.blocks) && enabled && mapInstance.current) {
      let blocksToUse = site.blocks

      if (!isEmpty(site.programSets) && !isUndefined(programSetSliderIndex) && !isEmpty(get(site, `programSets.${programSetSliderIndex}.programs`, []))) {
        blocksToUse = site.programSets[programSetSliderIndex].blocks
      }

      let blocks = map(blocksToUse, (block) => {
        let geoJson = block.geoJson

        return {
          ...geoJson,
          properties: {
            ...geoJson.properties,
            id: block.id,
            color: programSetStatusColour.pathColor === '#647087' ? '#1570ef' : programSetStatusColour.pathColor,
          },
        }
      })

      const blocksResult = addBlocks({
        blocks: blocks,
        drawingLayer: mapInstance.current.groups.drawings,
        clearLayer: true,
        withBounds: true,
      })

      if (blocksResult.bounds && blocksResult.bounds.isValid()) {
        mapInstance.current.map.fitBounds(blocksResult.bounds)
      }
    }
  }, [
    site,
    programSetSliderIndex,
    mapInstance,
    programSetStatusColour,
  ])

  const siteOptions = useMemo(() => {
    if (!isEmpty(sites)) {
      const groupedByArea = groupBy(sites, (site) => {
        return site.area.id
      })

      const result = map(groupedByArea, (sitesByArea) => {
        const siteOptions = map(sitesByArea, (site) => {
          return {
            label: site.name,
            value: site.id,
          }
        })

        return {
          label: head(sitesByArea).area.name,
          options: siteOptions,
        }
      })

      return result
    }
  }, [sites])

  const sliderSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  let timeoutId

  const manuallyNavigatedSlider = () => {
    didManuallyNavigateSlider.current = true

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      didManuallyNavigateSlider.current = false
    }, 60 * 1000)
  }

  // TODO: Consolidate the next and previous arrow to use one function
  function Arrow(props) {
    const {
      className,
      style,
      slider,
      arrowClass,
      next,
      prev,
    } = props

    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={() => {
          if (next) {
            slider.current.slickNext()
          }

          if (prev) {
            slider.current.slickPrev()
          }

          manuallyNavigatedSlider()
        }}
      >
        <i className={arrowClass}></i>
      </div>
    )
  }

  const runningProgramSets = useMemo(() => {
    return filter(allProgramSets, (programSet) => {
      const running = find(programSet.operationalStatuses, ['key', 'running'])

      return !isEmpty(running)
    })
  }, [allProgramSets])

  const [programSetLastUpdated, programLastUpdated] = useMemo(() => {
    const getLatestTimestamp = (stats) => {
      if (isEmpty(stats)) {
        return null
      }

      return maxBy(stats, (stat) => {
        return moment(stat.updatedAt).valueOf()
      })
    }

    const programSetLatest = getLatestTimestamp(programSetStats)
    const programLatest = getLatestTimestamp(programStats)
    const programSetLastUpdated = programSetLatest ? moment(programSetLatest.updatedAt).fromNow() : null
    const programLastUpdated = programLatest ? moment(programLatest.updatedAt).fromNow() : null

    return [programSetLastUpdated, programLastUpdated]
  }, [programSetStats, programStats])

  const preventSavingOnProgram = isInRunningState('program', displayedProgram?.id)
  const runningProgramNamesForProgram = getRelatedProgramNames('program', displayedProgram?.id)
  const preventSavingOnProgramSet = isInRunningState('programSet', displayedProgramSet?.id)
  const runningProgramNamesForProgramSet = getRelatedProgramNames('programSet', displayedProgramSet?.id)

  const [
    ,,,, programActiveStatus,
  ] = useMemo(() => {
    const stopped = find(displayedProgram.operationalStatuses, ['key', 'stopped'])
    const paused = find(displayedProgram.operationalStatuses, ['key', 'paused'])
    const running = find(displayedProgram.operationalStatuses, ['key', 'running'])
    const queued = find(displayedProgram.operationalStatuses, ['key', 'queued'])

    const activeStatus = find([
      stopped,
      paused,
      running,
      queued,
    ], (status) => {
      return status?.key
    })

    return [
      !isEmpty(stopped),
      !isEmpty(paused),
      !isEmpty(queued),
      !isEmpty(running),
      get(activeStatus, 'key', 'none'),
    ]
  }, [displayedProgram])

  const [
    programSetIsRunning,
    programSetIsPaused,
    programSetActiveStatus,
  ] = useMemo(() => {
    const stopped = find(displayedProgramSet.operationalStatuses, ['key', 'stopped'])
    const paused = find(displayedProgramSet.operationalStatuses, ['key', 'paused'])
    const running = find(displayedProgramSet.operationalStatuses, ['key', 'running'])
    const queued = find(displayedProgramSet.operationalStatuses, ['key', 'queued'])

    const activeStatus = find([
      stopped,
      paused,
      running,
      queued,
    ], (status) => {
      return status?.key
    })

    return [
      !isEmpty(running),
      !isEmpty(paused),
      get(activeStatus, 'key', 'none'),
    ]
  }, [displayedProgramSet])

  useEffect(() => {
    if (programActiveStatus === 'running' || programSetActiveStatus === 'running') {
      refreshInterval.current = setInterval(() => {
        updateDashboard()
      }, 120000)
    } else if (get(refreshInterval, 'current')) {
      clearInterval(refreshInterval.current)
      refreshInterval.current = null
    }

    return () => {
      clearInterval(refreshInterval.current)
    }
  }, [programActiveStatus, programSetActiveStatus])

  useEffect(() => {
    if (
      !isEmpty(allProgramSets) &&
      programSetSliderLoaded &&
      programSliderLoaded &&
      !didManuallyNavigateSlider.current
    ) {
      setTimeout(() => {
        const firstRunningSet = first(runningProgramSets)

        if (!isEmpty(firstRunningSet)) {
          const programSetIndex = findIndex(allProgramSets, { id: firstRunningSet.id })

          if (programSetIndex !== -1 && programSetIndex !== programSetSliderIndex) {
            programSetSlider.current.slickGoTo(programSetIndex)
            setProgramSliderIndex(0)
            setProgramSetSliderIndex(programSetIndex)
          }

          const firstRunningProgram = find(firstRunningSet.programs, (program) => {
            const running = find(program.operationalStatuses, ['key', 'running'])

            return !isEmpty(running)
          })

          if (!isEmpty(firstRunningProgram)) {
            const programIndex = findIndex(firstRunningSet.programs, { id: firstRunningProgram.id })

            if (programIndex !== -1 && programIndex !== programSliderIndex) {
              programSlider.current.slickGoTo(programIndex)
              setProgramSliderIndex(programIndex)
            }
          }
        }
      }, 500)
    }
  }, [
    allProgramSets,
    runningProgramSets,
    programSetSliderLoaded,
    programSliderLoaded,
  ])

  const timerArgsProgram = {
    identifyingKey: 'programId',
    identifyingId: displayedProgram.id,
    displayed: displayedProgram,
    stats: programStats,
    setSecondsRemaining: setProgramSecondsRemaining,
    status: programActiveStatus,
    setTimerOutOfSyncWarning: setTimerOutOfSyncWarning,
  }

  const timerArgsProgramSet = {
    identifyingKey: 'programSetId',
    identifyingId: displayedProgramSet.id,
    displayed: displayedProgramSet,
    stats: programSetStats,
    setSecondsRemaining: setProgramSetSecondsRemaining,
    status: programSetActiveStatus,
    setTimerOutOfSyncWarning: setTimerOutOfSyncWarning,
  }

  useTimer(timerArgsProgram)
  useTimer(timerArgsProgramSet)

  const [programSetRemaining, programSetElapsedPercentage] = useMemo(() => {
    if (displayedProgramSet.irrigationBasis === 'time') {
      if (programSetActiveStatus !== 'running' && programSetActiveStatus !== 'paused') {
        return [get(displayedProgramSet, 'timing.expectedTotalTime', '00:00:00'), 0]
      }

      let remainingRuntime = '00:00:00'
      let remainingRuntimePercentage = 100

      if (programSetSecondsRemaining > 0) {
        remainingRuntime = moment.utc(programSetSecondsRemaining * 1000).format('HH:mm:ss')
        remainingRuntimePercentage = clamp(100 - round(((programSetSecondsRemaining / displayedProgramSet.timing.expectedTotalSeconds) * 100), 4), 0, 100)
      }

      return [remainingRuntime, remainingRuntimePercentage]
    }

    if (displayedProgramSet.irrigationBasis === 'quantity') {
      const totalWaterQuantity = reduce(displayedProgramSet.programs, (sum, program) => {
        return sum + program.waterQuantity
      }, 0)

      if (programSetActiveStatus !== 'running' && programSetActiveStatus !== 'paused') {
        return [totalWaterQuantity, 0]
      }

      const remainingQuantityStat = find(displayedProgramSet.stats, { key: 'remainingQuantity' })
      const remainingQuantity = get(remainingQuantityStat, 'value', 0)
      const remainingQuantityPercentage = 100 - (remainingQuantity / totalWaterQuantity * 100)

      return [remainingQuantity, remainingQuantityPercentage]
    }
  }, [displayedProgramSet, programSetSecondsRemaining])

  const [programRemaining, programElapsedPercentage] = useMemo(() => {
    if (displayedProgramSet.irrigationBasis === 'time') {
      let remainingRuntime = '00:00:00'
      let remainingRuntimePercentage = 100

      if (programSecondsRemaining > 0) {
        remainingRuntime = moment.utc(programSecondsRemaining * 1000).format('HH:mm:ss')
        remainingRuntimePercentage = clamp(100 - round(((programSecondsRemaining / displayedProgram.timing.expectedTotalSeconds) * 100), 4), 0, 100)
      }

      return [remainingRuntime, remainingRuntimePercentage]
    }

    if (displayedProgramSet.irrigationBasis === 'quantity') {
      let remainingQuantity = find(displayedProgram.stats, { key: 'remainingQuantity' })
      remainingQuantity = get(remainingQuantity, 'value', 0)

      let remainingQuantityPercentage = (remainingQuantity / displayedProgram.waterQuantity * 100)

      return [remainingQuantity, remainingQuantityPercentage]
    }
  }, [
    programSecondsRemaining,
    displayedProgram,
    displayedProgramSet,
  ])

  const clickablePills = useMemo(() => {
    return {
      alarms: () => {
        setDashboardSelectedTab('alarms')
      },
    }
  }, [setDashboardSelectedTab])

  const pillClick = useCallback((key) => {
    if (isFunction(clickablePills[key])) {
      clickablePills[key]()
    }
  }, [clickablePills])

  const programSetHasAlarms = useMemo(() => {
    return some(displayedProgramSet.programs, (program) => {
      return !isEmpty(program.activeAlarms)
    })
  }, [displayedProgramSet])

  const handleTabChange = (value) => {
    setSelectedMapTab(value)

    if (value == 'site') {
      each(ioTypes, (ioType) => {
        mapInstance.current.map.removeLayer(mapLayerGroups.current[ioType])
      })

      mapInstance.current.map.addLayer(mapInstance.current.groups.marker)

      const bounds = latLngBounds()

      if (mapInstance.current.groups.marker) {
        bounds.extend(mapInstance.current.groups.marker.getBounds())
      }

      if (mapInstance.current.groups.drawings) {
        bounds.extend(mapInstance.current.groups.drawings.getBounds())
      }

      mapInstance.current.map.fitBounds(bounds)
    }

    if (value == 'inputOutputs') {
      let bounds

      each(ioTypes, (ioType) => {
        if (!bounds) {
          bounds = mapLayerGroups.current[ioType].getBounds()
        } else {
          bounds.extend(mapLayerGroups.current[ioType].getBounds())
        }

        mapInstance.current.map.addLayer(mapLayerGroups.current[ioType])
      })

      mapInstance.current.map.removeLayer(mapInstance.current.groups.marker)
    }
  }

  const MapComponent = () => {
    return (
      <div className="relative block h-full min-h-96">
        {siteOptions && (
          <div className="absolute right-2 top-2 z-1000 flex gap-3">
            {/* {!isEmpty(availableIoTypes) && (
              <OutsideClickHandler
                onOutsideClick={() => {
                  setFilterListOpen(false)
                }}
              >
                <div className={`${selectedMapTab !== 'inputOutputs' && 'hidden'} relative select-none`}>
                  <div
                    className={`${selectedMapTab !== 'inputOutputs' && 'hidden'}`}
                    onClick={() => {
                      return setFilterListOpen(!filterListOpen)
                    }}
                  >
                    <div className="flex h-10 items-center rounded border-gray-300 bg-white px-3 hover:cursor-pointer">
                      <i className="fa-regular fa-bars-filter mr-3"></i> Filters
                    </div>
                  </div>

                  <FilterList
                    ioTypes={availableIoTypes}
                    mapInstance={mapInstance}
                    mapLayerGroups={mapLayerGroups}
                    className={classNames({ hidden: !filterListOpen })}
                  />
                </div>
              </OutsideClickHandler>
            )} */}

            {
              (availableIoTypes && availableIoTypes.length) ?
                <Tabs
                  className="hidden p-0.5 sm:hidden xl:flex"
                  currentlySelected={selectedMapTab}
                  defaultTab="site"
                  tabs={[{
                    title: 'I/Os',
                    key: 'inputOutputs',
                  }, {
                    title: 'Site',
                    key: 'site',
                  }]}
                  onChange={handleTabChange}
                /> : null
            }
          </div>
        )}

        <Map
          onInit={mapInit}
          key={site.id}
          id={`map-site-${site.id}`}
          loading={isLoading && isEmpty(get(site, `programSets.${site.programSetIndex}.programs.${site.programIndex}`, []))}
        />
      </div>
    )
  }

  return (
    <div className="grid grid-flow-dense grid-cols-1 gap-2 sm:grid-cols-2 xl:grid-cols-4">
      <div className="col-start-1 col-end-2 row-span-1 row-start-1 sm:col-start-1 sm:col-end-3 xl:col-start-3 xl:col-end-5 xl:row-span-2">
        <Disclosure defaultOpen={true}>
          {({ open }) => {
            return (
              <>
                <div className="flex w-full items-center justify-between rounded-t bg-white p-3 py-2 xl:hidden">
                  <div className="text-md">Map</div>

                  <div className="flex">
                    {
                      (availableIoTypes && availableIoTypes.length) ?
                        <Tabs
                          currentlySelected={selectedMapTab}
                          defaultTab="site"
                          tabs={[{
                            title: 'I/Os',
                            key: 'inputOutputs',
                          }, {
                            title: 'Site',
                            key: 'site',
                          }]}
                          onChange={handleTabChange}
                          className="mr-5 h-10"
                        /> : null
                    }

                    <Disclosure.Button as="span" className="flex items-center justify-center hover:cursor-pointer">
                      <i className={classNames('fa fa-chevron-down flex aspect-square h-10 items-center justify-center rounded border', { 'rotate-180': !open })}></i>
                    </Disclosure.Button>
                  </div>
                </div>

                <Disclosure.Panel unmount={false} className="block h-map-height text-gray-500 xl:h-full">
                  {MapComponent()}
                </Disclosure.Panel>
              </>
            )
          }}
        </Disclosure>
      </div>

      <Card className="h-full">
        <Card.Header
          title={displayedProgramSet?.name}
          className="flex items-center justify-between gap-1 p-3.5"
        >
          <div className="flex flex-1">
            {displayedProgramSet?.operationalStatuses && (
              map(displayedProgramSet?.operationalStatuses, (status) => {
                return (
                  <div
                    onClick={() => {
                      return pillClick(status.key)
                    }}
                    className={classNames({ 'cursor-pointer': !!clickablePills[status.key] })}
                    key={status.key}
                  >
                    <Pill color={status.color} hasDot={true} key={status.title}>{status.title}</Pill>
                  </div>
                )
              })
            )}

            {(programSetHasAlarms || siteHasAlarms) && (
              <div>
                <Pill color="#F04438" hasDot={true}>Alarms</Pill>
              </div>
            )}
          </div>

          {auth.can('update-program') ? (
            <div className="text-right">
              <DropdownList
                icon={<ActionIcon />}
                options={[
                  {
                    label: 'Edit program set',
                    disabledWithReason: preventSavingOnProgramSet && runningProgramNamesForProgramSet,
                    onClick: () => {
                      setModal({
                        name: 'set',
                        data: {
                          programSet: displayedProgramSet,
                          site: site,
                          isEditing: true,
                          onSave: () => {
                            updateDashboard()
                          },
                        },
                      })
                    },
                  },
                  {
                    label: (<span><i className="fa-sharp fa-solid fa-pause fa-lg mr-2 text-gray-500"></i> Pause program set</span>),
                    topLine: true,
                    disabled: !programSetIsRunning,
                    onClick: () => {
                      setModal({
                        name: 'warning',
                        data: {
                          endpoint: `/program-set/state/pause/${displayedProgramSet?.id}`,
                          title: 'Pause program set',
                          content: 'Pausing this program set will cause all related programs to pause as well. They will remain inactive until the set is activated again. Do you wish to proceed?',
                          successFlashMessage: `Successfully queued ${displayedProgramSet?.name} for pausing.`,
                          onComplete: () => {
                            setModal(null)
                          },
                          close: () => {
                            setModal(null)
                          },
                          onFailure: () => {
                            setModal(null)
                            setAlert({
                              type: 'error',
                              content: 'We were unable to process your request. Please try again.',
                            })
                          },
                        },
                      })
                    },
                  },
                  {
                    label: (<span><i className="fa-sharp fa-solid fa-play fa-lg mr-2 text-green-600"></i> Resume program set</span>),
                    topLine: true,
                    disabled: !programSetIsPaused,
                    onClick: () => {
                      setModal({
                        name: 'warning',
                        data: {
                          endpoint: `/program-set/state/resume/${displayedProgramSet?.id}`,
                          title: 'Resume program set',
                          content: 'Are you sure you want to resume this set? Program stop times (if set) will still be respected and proceed accordingly. The program set will automatically start again on the next scheduled day.',
                          successFlashMessage: `Successfully queued ${displayedProgramSet?.name} to resume.`,
                          onComplete: () => {
                            setModal(null)
                          },
                          close: () => {
                            setModal(null)
                          },
                          onFailure: () => {
                            setModal(null)
                            setAlert({
                              type: 'error',
                              content: 'We were unable to process your request. Please try again.',
                            })
                          },
                        },
                      })
                    },
                  },
                  {
                    label: (<span><i className="fa-sharp fa-solid fa-stop fa-lg mr-2 text-red-500"></i> Stop program set</span>),
                    topLine: true,
                    onClick: () => {
                      setModal({
                        name: 'warning',
                        data: {
                          endpoint: `/program-set/state/stop/${displayedProgramSet?.id}`,
                          title: 'Stop program set',
                          content: 'Stopping this program set will cause all related programs to stop as well. They will remain inactive until the set is activated again. Do you wish to proceed?',
                          successFlashMessage: `Successfully queued ${displayedProgramSet?.name} to stop.`,
                          onComplete: () => {
                            setModal(null)
                          },
                          close: () => {
                            setModal(null)
                          },
                          onFailure: () => {
                            setModal(null)
                            setAlert({
                              type: 'error',
                              content: 'We were unable to process your request. Please try again.',
                            })
                          },
                        },
                      })
                    },
                  },
                ]}
              />
            </div>
          ) : ''}
        </Card.Header>

        <Card.Body className="p-3.5">
          <Slider
            {...sliderSettings}
            ref={programSetSlider}
            nextArrow={<Arrow slider={programSetSlider} next arrowClass="fa-regular fa-chevron-right" />}
            prevArrow={<Arrow slider={programSetSlider} prev arrowClass="fa-regular fa-chevron-left" />}
            onInit={() => {
              setProgramSetSliderLoaded(true)
            }}
            beforeChange={() => {
              setProgramSliderIndex(0)
            }}
            afterChange={(currentSlide) => {
              setProgramSetSliderIndex(currentSlide)
            }}
            className="program-slider"
          >
            {map(allProgramSets, (set) => {
              return <div key={`set-${set.id}`}>
                <div className="mb-3 grid grid-cols-2">
                  <div>
                    {set.irrigationBasis === 'time' && (
                      <div className="flex flex-wrap justify-start text-sm">
                        <div className="mr-1 text-gray-500">Total runtime:</div>{get(set, 'timing.expectedTotalTime', '-')}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col items-end">
                    <div className="flex flex-col">
                      <div className="flex flex-wrap text-sm">
                        <div className="mr-1 text-gray-500">Start time:</div>{set.startTime.substring(0, 5)}
                      </div>

                      <div className="flex flex-wrap text-sm">
                        <div className="mr-1 text-gray-500">Stop time:</div>{!set.stopTimeDisabled ? set.stopTime.substring(0, 5) : 'Disabled'}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1">
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-40">
                      <CircularProgressbarWithChildren
                        value={programSetElapsedPercentage}
                        strokeWidth="10"
                        styles={buildStyles({
                          textColor: 'red',
                          pathColor: programSetSiteAlarmStatusColour.pathColor,
                          trailColor: programSetSiteAlarmStatusColour.trailColor,
                        })}
                      >
                        <div className="flex items-center text-xs text-gray-500">
                          {timerOutOfSyncWarning && (
                            <Tippy
                              theme="light"
                              placement="auto"
                              trigger="click"
                              interactive={true}
                              content={
                                <>
                                  Stop command not received.
                                  Timers may be outdated.
                                  Click <button onClick={() => {
                                    window.location.reload()
                                  }} className="text-status-queued underline">here</button> to refresh the page.
                                  If the issue persists, contact support.
                                </>
                              }
                            >
                              <i className="fa fa-exclamation-triangle mr-1 text-red-500 hover:cursor-pointer"></i>
                            </Tippy>
                          )}

                          {isLoading ? (
                            <>
                              <div className="primary-loader tiny mr-1"></div>
                              <div>Calculating</div>
                            </>
                          ) : 'Remaining'}
                        </div>
                        <div className="text-xl font-medium">
                          {programSetRemaining}
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </Slider>

          {programSetLastUpdated && (
            <div className="mt-3 flex text-xs text-gray-400">
              Updated {programSetLastUpdated}
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="col-start-1 col-end-1 row-span-1 row-start-2 h-full sm:col-start-1 sm:col-end-2 xl:col-start-2 xl:col-end-3 xl:row-start-1">
        <Card.Header
          title="Schedule"
          className="p-3.5"
        />

        <Card.Body className="flex items-center justify-center p-3.5">
          <DayPicker initialSchedule={displayedProgramSet?.schedule} noBorder={true} displayOnly={true} className="max-w-96" />
        </Card.Body>
      </Card>

      <Card className="h-full">
        <Card.Header
          title={displayedProgram?.name || ''}
          className="flex items-center justify-between gap-1 p-3.5"
        >
          <div className="flex flex-1">
            {displayedProgram?.disabled ? (
              <Pill color="#647087">Disabled</Pill>
            ) : displayedProgram?.operationalStatuses && (
              map(displayedProgram?.operationalStatuses, (status) => {
                return (
                  <div
                    onClick={() => {
                      return pillClick(status.key)
                    }}
                    className={classNames({ 'cursor-pointer': !!clickablePills[status.key] })}
                    key={status.key}
                  >
                    <Pill color={status.color} hasDot={true} key={status.title}>{status.title}</Pill>
                  </div>
                )
              })
            )}

            {(!isEmpty(displayedProgram?.activeAlarms) || siteHasAlarms) && (
              <div>
                <Pill color="#F04438" hasDot={true}>Alarms</Pill>
              </div>
            )}
          </div>

          {auth.can('update-program') ? (
            <div className="text-right">
              <DropdownList
                icon={<ActionIcon />}
                options={[{
                  label: 'Edit program',
                  disabledWithReason: preventSavingOnProgram && runningProgramNamesForProgram,
                  onClick: () => {
                    setModal({
                      name: 'program',
                      data: {
                        program: displayedProgram,
                        isEditing: true,
                        onSave: () => {
                          updateDashboard()
                        },
                      },
                    })
                  },
                }, {
                  label: 'Manage program',
                  onClick: () => {
                    navigate(`/program/manage/${displayedProgram?.id}`)
                  },
                }]}
              />
            </div>
          ) : ''}
        </Card.Header>

        <Card.Body className="flex flex-col justify-center p-3.5">
          {isEmpty(displayedProgramSet?.programs) && (
            <div className="flex justify-center self-center p-4 text-sm text-slate-500">No data</div>
          )}

          <Slider
            {...sliderSettings}
            ref={programSlider}
            nextArrow={<Arrow slider={programSlider} next arrowClass="fa-regular fa-chevron-right" />}
            prevArrow={<Arrow slider={programSlider} prev arrowClass="fa-regular fa-chevron-left" />}
            onInit={() => {
              setProgramSliderLoaded(true)
            }}
            afterChange={(currentSlide) => {
              setProgramSliderIndex(currentSlide)
            }}
            className="program-slider flex h-full flex-col"
          >
            {map(displayedProgramSet?.programs, (program) => {
              return (
                <div key={`program-${program.id}`}>
                  {displayedProgramSet.irrigationBasis === 'time' && (
                    <div className="mb-3 grid grid-cols-2">
                      <div className="flex justify-start text-sm"><div className="mr-1 text-gray-500">Runtime:</div>{program.runtime ? program.runtime.substring(0, 8) : 'Not Set'}</div>
                    </div>
                  )}

                  <div className="mx-auto w-40">
                    <CircularProgressbarWithChildren
                      value={programElapsedPercentage}
                      strokeWidth="10"
                      styles={buildStyles({
                        textColor: 'red',
                        pathColor: programSiteAlarmStatusColour.pathColor,
                        trailColor: programSiteAlarmStatusColour.trailColor,
                      })}
                    >
                      <div className="flex items-center text-xs text-gray-500">
                        {timerOutOfSyncWarning && (
                          <Tippy
                            theme="light"
                            placement="auto"
                            trigger="click"
                            interactive={true}
                            content={
                              <>
                                Stop command not received.
                                Timers may be outdated.
                                Click <button onClick={() => {
                                  window.location.reload()
                                }} className="text-status-queued underline">here</button> to refresh the page.
                                If the issue persists, contact support.
                              </>
                            }
                          >
                            <i className="fa fa-exclamation-triangle mr-1 text-red-500 hover:cursor-pointer"></i>
                          </Tippy>
                        )}

                        {isLoading ? (
                          <>
                            <div className="primary-loader tiny mr-1"></div>
                            <div>Calculating</div>
                          </>
                        ) : (
                          displayedProgramSet.irrigationBasis === 'time' ? 'Remaining' : 'Total'
                        )}
                      </div>
                      <div className="text-xl font-medium">
                        {programRemaining}
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
              )
            })}
          </Slider>

          {programLastUpdated && (
            <div className="mt-3 flex text-xs text-gray-400">
              Updated {programLastUpdated}
            </div>
          )}
        </Card.Body>
      </Card>

      {displayedProgram.mainLine && (
        <MainLineCard
          displayedProgram={displayedProgram}
          programStatusColour={programStatusColour}
        />
      )}

      {auth.isSuperAdmin && !isEmpty(displayedProgram?.fertigationPumps) && (
        map(displayedProgram.fertigationPumps, (pump) => {
          return <div key={pump.id}>
            <FertigationCard
              pump={pump}
              displayedProgram={displayedProgram}
              programActiveStatus={programActiveStatus}
              programStatusColour={programStatusColour}
            />
          </div>
        })
      )}

      <InputOutputCards
        site={site}
        displayedProgram={displayedProgram}
        programActiveStatus={programActiveStatus}
        programStatusColour={programStatusColour}
        setModal={setModal}
        setAlert={setAlert}
        updateDashboard={updateDashboard}
      />
    </div>
  )
}

export default DashboardOverview
